<template>
    <div>
    

        <!---main body--->


        <section :class="($store.getters.isMobile) ? 'mt-n12 mr-1 ml-1 mb-16' : 'm-t mr-4 ml-4'">
            <v-col class="mb-16">
                <v-data-table fixed-header :headers="headers" class="elevation-2 bordered-table"
                    :items="packageDeal.data" :options.sync="packageOptions" :server-items-length="packageTotal"
                    :items-per-page="packageSearch.itemsPerPage" :loading="loading" hide-default-footer>

                    <template v-slot:[`item.action`]="{ item }">
                        <!-- Wrapper with flex to align items horizontally -->
                        <div style="display: flex; align-items: center; gap: 10px;">
                            <!-- Edit Icon -->
                     
 <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
        <router-link :to="'/add-packageDeal/' + item.id" class="mr-2" v-bind="attrs" v-on="on" style="cursor: pointer;">
            <v-icon size="18" color="primary">
                mdi-pencil
            </v-icon>
        </router-link>
    </template>
    <span>Click to Edit</span>
</v-tooltip>

                            <!-- Deactivate Icon -->
                            <v-tooltip bottom v-if="item.status != '0'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="18" color="green" class="mr-2" v-bind="attrs" v-on="on"
                                        @click="changeStatus(item, 1)" style="cursor: pointer;">
                                        mdi-toggle-switch-off
                                    </v-icon>
                                </template>
                                <span>Click to Deactivate</span>
                            </v-tooltip>

                            <!-- Activate Icon -->
                            <v-tooltip bottom v-else>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="18" color="red" class="mr-2" v-bind="attrs" v-on="on"
                                        @click="changeStatus(item, 0)" style="cursor: pointer;">
                                        mdi-toggle-switch
                                    </v-icon>
                                </template>
                                <span>Click to Activate</span>
                            </v-tooltip>
                        </div>
                    </template>

                    <template v-slot:top>
                        <v-row class="ma-2">
                            <div> 
                                <v-breadcrumbs
                                            :class="($store.getters.isMobile) ? 'text-blue ml-n2 mt-n4' : 'text-blue ml-10'">
                                            <v-breadcrumbs-item>Home
                                                <v-icon size="18" color="#000000">mdi-chevron-right</v-icon>
                                            </v-breadcrumbs-item>
                                            <v-breadcrumbs-item>Master
                                                <v-icon size="18" color="#000000">mdi-chevron-right</v-icon>
                                            </v-breadcrumbs-item>
                                            <v-breadcrumbs-item>Package Price
                                                <v-icon size="18" color="#000000">mdi-chevron-right</v-icon>
                                            </v-breadcrumbs-item>
                                            <v-breadcrumbs-item class="text-light">Add Package</v-breadcrumbs-item>
                                        </v-breadcrumbs>
                                    </div>
                                   
                               <v-card-title>Package Details : (Total: {{
                                (packageDeal.meta) ? packageDeal.meta.total : 0
                                }})
                            </v-card-title>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" dark :class="($store.getters.isMobile) ? 'ml-4 mt-n2' : 'mt-5 mr-5'"
                                @click="$router.push('/add-packageDeal/')">
                                Add
                            </v-btn>
                        </v-row>
                    </template>
                    <template v-slot:no-data>
                        <v-btn color="primary">
                            Reset
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12">
                <v-row class="justify-end">
                    <v-col cols="3">
                        <v-select dense :items="itemsPerPageOptions" label="Items per page"
                            v-model="packageSearch.itemsPerPage" outlined @change="getPackage()"></v-select>
                    </v-col>
                    <v-pagination v-model="packageSearch.page"
                        :length="(packageDeal.meta) ? packageDeal.meta.last_page : 0" :total-visible="7"
                        @input="getPackage"></v-pagination>
                </v-row>
            </v-col>
        </section>

    </div>
</template>


<script>
import PackageDeal from "@/apis/PackageDeal.js";

export default {
    name: "PackagePrice",

    data: () => ({
        packageDeal: [],
        valid: false,

        loading: false,
        packageOptions: {},
        packageTotal: 0,

        headers: [
            { text: '#id', align: 'start', sortable: true, value: 'id', },
            { text: 'Package', align: 'start', sortable: true, value: 'name', },
            { text: 'Category', align: 'start', sortable: false, value: 'package.name', },
            { text: 'Type', align: 'start', sortable: true, value: 'type', },
            { text: 'PC', align: 'start', sortable: true, value: 'post_count' },
            { text: 'Validity', align: 'start', sortable: true, value: 'validity' },
            { text: 'Price', align: 'start', sortable: true, value: 'price' },
            { text: 'Disc(%)', align: 'start', sortable: true, value: 'discount' },
            { text: 'Final_Price', align: 'start', sortable: true, value: 'net_price' },
            { text: 'Total+GST', align: 'start', sortable: true, value: 'gross_price' },
            { text: 'Action', align: 'start', sortable: true, value: 'action' },
        ],
        packageSearch: {
            page: 1,
            search: "",
            itemsPerPage: 20,
            sortBy: [],
            sortDesc: [],
        },
        itemsPerPageOptions: [30, 40, 50, 100],
        last_page: 1,
        changeForm: {
            status: ""
        },
    }),

    mounted() {

    },

    created() {
    },

    computed: {},

    methods: {
        checkUserPermission: function (needle) {
            var ability = this.$store.state.ability;
            var length = ability?.length;
            for (var i = 0; i < length; i++) {
                if (ability[i] == needle) return true;
            }
            return false;
        },
        getPackage() {
            this.loading = true;
            PackageDeal.index(this.packageSearch)
                .then((response) => {
                    this.packageDeal = response.data;
                    this.loading = false;
                    this.packageTotal = response.data.meta.total;
                })
        },

        changeStatus(item, status) {
            this.loading = true;

            const fd = new FormData();
            fd.append('status', status);

            const action = status === 0 ? "Activate" : "Deactivate";

            if (confirm(`Are you sure you want to ${action} this package?`)) {
                PackageDeal.change_status(fd, item.id)
                    .then(() => {
                        const message =
                            status === 0
                                ? "Activated Package Successfully!"
                                : "Deactivated Package Successfully!";

                        this.$notify({
                            title: "Success",
                            text: message,
                            type: "success",
                            duration: 10000,
                        });

                        this.getPackage();
                        this.loading = false;
                    })
                    .catch((errors) => {
                        if (errors.response === undefined) {
                            location.reload();
                        }

                        let text = errors.response.data.message;
                        if (errors.response.data.errors) {
                            for (const value in errors.response.data.errors) {
                                if (text) text += "<br>";
                                text += errors.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            dangerouslyUseHTMLString: true,
                            position: "top-right",
                            duration: 15000,
                        });
                        this.loading = false;
                    });
            } else {
                this.loading = false;
            }
        },

        deletePackage(packageDeal) {
            if (confirm("Are You Sure ?")) {
                PackageDeal.delete(packageDeal.id)
                    .then(() => {
                        this.getPackage();
                        this.$notify({
                            title: "Success",
                            text: "Package Deleted Successfully !!",
                            type: "success",
                            duration: 10000,
                        });
                    })
            }
        },
    },

    watch: {
        "packageSearch.page": function () {
            this.getPackage();
        },
        packageOptions: {
            handler(val) {
                this.packageSearch.page = val.page;
                this.packageSearch.itemsPerPage = val.itemsPerPage;
                this.packageSearch.sortBy = val.sortBy;
                this.packageSearch.sortDesc = val.sortDesc;
                this.getPackage();
            },
            deep: true,
        },
    },
};

</script>
<style scoped>
.bordered-table {
    border: 1px solid #ddd !important;
    /* Add border to the entire table */
    border-radius: 4px;
    /* Optional rounded corners */
    overflow: hidden;
    /* To clip the content inside the border */
    width: 100%;
    /* Ensure the table spans the full width */
    background-color: #fff;
    /* Optional: set a background color */
}

.bordered-table th,
.bordered-table td {
    border: 1px solid #000000 !important;
    /* Add borders to individual cells */
    padding: 10px;
    /* Padding for better readability */
    text-align: left;
    /* Align text to the left */
}

.bordered-table th {
    background-color: #1976D2 !important;
    /* UPDATED CODE: Blue background for header */
    color: white !important; /* White text */
    font-weight: bold;
}


.v-application .pt-4 {
    padding-top: 31px !important;
}
.v-application .ml-10 {
    margin-left: 0px !important;
}


.v-application ol, .v-application ul {
    padding-left: 0px;
}
.header-column {
    background-color: #1976D2; /* Custom background color (blue) */
    color: white; /* Custom font color (white) */
    font-weight: bold;
    padding: 16px; /* Custom padding for header cells */
}

/* If you want more specific header styling */


</style>
<style scoped>
/* Apply styles to the header of v-data-table */
.v-data-table thead th {
    background-color: #216eae !important; /* Blue background for header */
    color: white !important;  /* White text color */
    font-weight: bold !important;  /* Bold font weight */
    text-align: left !important;  /* Align header text to the left */
      vertical-align: middle !important; /* Vertically align text in the middle */

    padding: 10px 16px !important; /* Padding for the header cells */
}

/* Optional: Add borders around table and cells */
.v-data-table th,
.v-data-table td {
    border: 1px solid #ddd !important;
}

/* Deep selector to ensure styles apply correctly */
::v-deep .v-data-table th {
    background-color: #216eae !important; /* Blue background */
    color: white !important; /* White text */
    font-weight: bold !important; /* Bold text */
    vertical-align: middle !important; /* Vertically align text in the middle */
}
</style>